var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "10"
    }
  }, [_c('form-tanggal', {
    on: {
      "submit": _vm.submit
    }
  }), _vm.profitLossContent ? _c('b-card', [_c('div', {
    staticClass: "d-flex justify-content-end mb-2"
  }, [_c('b-button', {
    staticClass: "text-right",
    attrs: {
      "variant": "outline-primary",
      "disabled": !this.profitLossContent
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.print($event);
      }
    }
  }, [_vm._v("Download")])], 1), _c('section', {
    attrs: {
      "id": "table-content"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.profitLossContent)
    }
  })]) : _c('div', {
    staticClass: "alert alert-danger p-1"
  }, [_vm._v(" Harap pilih tanggal awal & tanggal akhir ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }